import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { createFocusTrap } from 'focus-trap';

export default class StoryBackdropController extends Controller {
  static values = {
    returnUrl: String,
    shouldRedirect: Boolean,
  };

  connect() {
    document.body.classList.add('overflow-hidden');

    this.focusTrap = createFocusTrap(this.element, {
      clickOutsideDeactivates: true,
      onDeactivate: () => {
        this.returnOrClose();
      },
    });

    this.focusTrap.activate();
  }

  disconnect() {
    document.body.classList.remove('overflow-hidden');
    this.focusTrap?.deactivate();
  }

  returnOrClose() {
    if (this.shouldRedirectValue) {
      Turbo.visit(this.returnUrlValue);
    } else {
      this.element.remove();
      history.pushState(history.state, '', this.returnUrlValue);
    }
  }

  close() {
    this.returnOrClose();
  }
}
